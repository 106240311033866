import React from "react"
import Layout from '../layouts/layout'

const NotFound = () => {
  
  return (
    <Layout>
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <div className="error-inner">
                <h1>404</h1>
                <h2 className="title">Something’s not right.</h2>
                <p className="description"> We can't find the page you're looking for. Check out our home page!</p>               
                <div className="view-more-button">
                    <a className="btn-default" href="/">Go Back Home</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
